import React from 'react'
import styled from '@emotion/styled'

const SeparatorD = styled.div`
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255, .1);
    border-top: 1px solid rgba(0,0,0, .1);
`


export default function Separator( props ) {
    const {style} = props;
    return <SeparatorD style={style}/>
}
