export const theme = {
  colors: {
    dark: {
      background: "#2B2C30",
      primary: "#42C093",
    },
  },
  spaces: {
    blogMaxWidth: 960,
  }
}
