import React from 'react'
import { Link } from 'gatsby'
import SocialMedia from './SocialMedia'
import Separator from './Separator'

export default function Footer() {
    return (
        <>
        <Separator />
        <footer className="footer">
            
            <SocialMedia />
            <nav>
                <ul className="nav">
                    <li>
                        <Link to="/legal-notice">Legal Notice</Link>
                    </li>
                    <li>
                        <Link to="/privacy">Privacy</Link>
                    </li>
                </ul>
            </nav>
            <div>Thomas Zipner © 2020</div>
        </footer>
        </>
    )
}
