import React from "react"
import styled from "@emotion/styled"
import { Github, LinkedIn, Xing } from "../assets/icons"
// Twitter,Youtube, Skype,

const SMList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;

  

  li {
    padding: 0 5px;

    &:hover {
      transform: scale(1.1);

      path {
        fill: var(--primary-color);
        fill-opacity: 1;
      }
    }
  }

    &.whiteHover {
      li {
        &:hover {    
          path {
            fill: #fff;
            fill-opacity: 1;
          }
        }
      }
    }

  }
`

const smLinks = [
  /*{
    name: "Twitter",
    link: "https://twitter.com/ThomasZipner",
    icon: <Twitter />,
  },*/

  /*{
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCULMu4U1V4d319HWwtnqkhQ",
    icon: <Youtube />,
  },*/
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/thomas-zipner",
    icon: <LinkedIn />,
  },
  {
    name: "Xing",
    link: "https://www.xing.com/profile/Thomas_Zipner/cv",
    icon: <Xing />,
  },
  {
    name: "GitHub",
    link: "https://github.com/thommesZip",
    icon: <Github />,
  },

  /*{
    name: "Skype",
    link: "https://www.youtube.com/channel/UCULMu4U1V4d319HWwtnqkhQ",
    icon: <Skype />,
  },*/
]

export default function SocialMedia(props) {
  const { hoverColorClass } = props

  return (
    <SMList className={hoverColorClass ? hoverColorClass : ""}>
      {smLinks.map(channel => {
        return (
          <li key={channel.name}>
            <a href={channel.link} target="_blank" rel="noreferrer">
              {channel.icon}
            </a>
          </li>
        )
      })}
    </SMList>
  )
}
