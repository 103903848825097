import React from "react"
import { ThemeProvider } from "emotion-theming"
import { theme } from "./theme/theme"

const defaultState = {
  navOpen: false,
  toggleNav: () => {},
}

const GlobalContext = React.createContext(defaultState)

const GlobalContextProvider = (props) => {

  const [context, setContextState] = React.useState(defaultState);
  
  function setContext ( updates = {}){
    setContextState({...context, ...updates})
  }

  return (
    <GlobalContext.Provider value={[context, setContext]}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </GlobalContext.Provider>
  );
}

/*

class GlobalContextProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navOpen: false,
      toggleNav: this.toggleNav,
    }
  }

  toggleNav = () => {
    this.setState({ navOpen: !this.state.navOpen })
  }

  render() {
    return (
      <GlobalContext.Provider value={{ ...this.state }}>
        <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>
      </GlobalContext.Provider>
    )
  }
}
*/
export { GlobalContext, GlobalContextProvider };
