import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
//import { navigate } from '@reach/router';
import CookieConsent from "react-cookie-consent"
import Cookies from "js-cookie"
import Header from "./Header"
import Navigation from "./Navigation"
import Footer from "./Footer"
import Separator from "./Separator"
import SEO from "./seo"

const noCookieLawBanner = ["/privacy", "/legal-notice"]

export default function Layout(props) {
  const { title, path } = props
  const [userHasChosen, setUserHasChosen] = useState(
    Cookies.get("gatsby-gdpr-google-analytics") !== undefined
  )

  return (
    <>
      <SEO />
      <div className="largeWrapper">
        <Helmet>
          <title>{title}</title>
          <link rel="shortcut icon" href="/favicon.svg" type="image/svg+xml" />
          <link rel="shortcut icon" href="/favicon.png" type="image/png" />
          <meta name="theme-color" content="#2B2C30" />
        </Helmet>

        <Header />
        <main>{props.children}</main>
        <Footer />

        <Navigation isMobile />
      </div>

      {!userHasChosen && !noCookieLawBanner.includes(path) ? (
        <div className="cookieLawBannerOverlay">
          <CookieConsent
            location="bottom"
            buttonText="Alright, no problem"
            cookieName="gatsby-gdpr-google-analytics"
            containerClasses="cookieLawBanner"
            declineButtonClasses="button button--dark"
            buttonClasses="button"
            buttonWrapperClasses="cookieLawBanner__btns"
            contentClasses="cookieLawBanner__content"
            disableStyles={true}
            expires={365}
            enableDeclineButton={false}
            onDecline={() => {
              setUserHasChosen(true)
            }}
            onAccept={() => {
              const { href } = props.location // pathname, search, 
              // const path = `${pathname}${search || ''}`
              window.location = href
              setUserHasChosen(true)
            }}
          >
            <h2 className="primary-color">Privacy</h2>
            <Separator />
            <p>
              My website uses cookies for analytics. By continuing to browse
              this site, you agree to this use. Read more{" "}
              <Link to="/privacy">here</Link>.
            </p>
            <Separator />
          </CookieConsent>
        </div>
      ) : null}
    </>
  )
}

// <Navigation isMobile />
