import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import SocialMedia from "./SocialMedia"
import { LogoWhite } from '../assets/icons'
import Separator from '../components/Separator'
import { GlobalContext } from '../globalContext'


const LogoAndSocial = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  

  ul {
    padding: 0;
    width: auto;
    }
  }

  div {
    display: inline-block;
  }

  /*
  @media only screen and (min-width: 600px) {
    left: 50%;
    transform: translate(-50%);
  }
  */
`

// const isPartiallyActive = ({
//   isPartiallyCurrent
// }) => {
//   return isPartiallyCurrent
//     ? { className: "active" }
//     : {}
// }

export default function Navigation(props) {
  const { isMobile } = props

  if (isMobile) {
    return <MobileNav {...props} />
  }

  return <DesktopNav {...props} />
}

function DesktopNav(props) {
  return (
    <div className={`desktopNav`}>
      <div>
        <ul>
          <li>
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </li>
          {/* <li>
            <Link
              to="/blog"
              activeClassName="active"
            >
              Blog
            </Link>
          </li> */}

          <li>
            <Link
              to="/about-me"
              activeClassName="active"
            >
              About me
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

function MobileNav(props) {

  const [{navOpen}, setContext] = React.useContext(GlobalContext)
  const [scrolled, setScrolled  ] = React.useState(false);

  const handleScroll = React.useCallback(() => {
    const offset=window.scrollY;
    if(offset > 80 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
      setContext({navOpen: false})
    }
  }, [setContext])

  React.useEffect( () => {
      document.body.classList.toggle('navOpen',navOpen)
  },[navOpen])

  React.useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  },[handleScroll])

  return (
    <>
    <div className={`mobileNav ${navOpen ? "open" : ""} ${scrolled ? "stickIt" : ""}`}>

      <div className="mobileNav__trigger" onClick={e => setContext({navOpen: !navOpen})} aria-label="Open the menu">
        <div
          className={`hamburger hamburger--emphatic ${
            navOpen ? "is-active" : ""
          }`}
          aria-hidden={navOpen ? "false" : "true"}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </div>
      </div>
      
      <div className={`mobileNav__content ${navOpen ? "open" : ""}`}>
       
        <LogoAndSocial className="ls">
        <LogoWhite />
        <Separator style={{maxWidth: "220px", marginTop: "10px"}}/>
        <SocialMedia hoverColorClass="whiteHover" />
      </LogoAndSocial>
        <ul className="nav">
          <li>
            <Link
              to="/"
              activeClassName="active"
              onClick={e => setContext({navOpen: !navOpen})}
            >
              Home
            </Link>
            {/* <Separator /> */}
          </li>

          {/* <li>
            <Link
              to="/blog"
              activeClassName="active"
              onClick={e => setContext({navOpen: !navOpen})}
            >
              Blog
            </Link>
            <Separator />
          </li> */}

          <li>
            <Link
              to="/about-me"
              activeClassName="active"
              onClick={e => setContext({navOpen: !navOpen})}
            >
              About me
            </Link>
            {/* <Separator /> */}
          </li>
          
          <li>
            <Link
              to="/contact"
              activeClassName="active"
              onClick={e => setContext({navOpen: !navOpen})}
            >
              Contact
            </Link>
            {/* <Separator /> */}
          </li>
        </ul>
      </div>
    </div>
    <div className="mobileNav__border" />
    </>
  )
}
