import React from "react"
import { Link } from 'gatsby'
import SocialMedia from "./SocialMedia"
import styled from "@emotion/styled"

import { Logo } from '../assets/icons'
import Navigation from "./Navigation"

const HeaderWrapper = styled.header`


`

export default function Header() {

  const [scrolled, setScrolled  ] = React.useState(false);

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 80 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })


  return (
    <HeaderWrapper className={scrolled ? "stickIt" : ""}>
      <div>
      <Link to="/" activeClassName="active">
        <Logo />
      </Link>
      </div>
      <Navigation />
      <div className="d-none d-md-block">
        <SocialMedia />
      </div>
    </HeaderWrapper>
  )
}
